import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LinkButton from '../../elements/linkButton'
import { breakpoint } from '../../shared/theme'
import InnerWrapper from '../../elements/innerWrapper'

const Header = styled.div`
	margin-bottom: 20px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 48px;
	}
`

export const HomeSectionHeader = ({ link, isActive }) => {
	return (
		<Header>
			<InnerWrapper>
				<LinkButton path={link.url} title={link.title} isActive={isActive} />
			</InnerWrapper>
		</Header>
	)
}

HomeSectionHeader.propTypes = {
	path: PropTypes.string,
	title: PropTypes.string,
	isActive: PropTypes.bool,
}

HomeSectionHeader.defaultProps = {
	link: {
		path: `#`,
		title: `Lorem Ipsum`,
	},
	isActive: false,
}
