import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import WpLink from '../../elements/wpLink'
import { Link } from 'gatsby'
import { breakpoint, getColor, ease } from '../../shared/theme'

const Card = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;

	a {
		position: relative;
		display: flex;
		align-items: flex-end;

		&::before {
			content: '';
			width: 100%;
			display: block;
			height: 0;
			padding-top: 60%;
			background: ${getColor('black')};

			@media ${breakpoint('tablet')} {
				padding-top: 100%;
			}

			@media ${breakpoint('laptop')} {
				padding-top: 157.5%;
			}
		}

		img {
			opacity: 0.8;
			transition: all 200ms ${ease};
		}
	}

	&:hover {
		img {
			opacity: 0.6;
			transform: scale(1.05);
		}
	}

	@media ${breakpoint('tablet')} {
		width: calc((6 / 12 * 100%) - (2px * 3 / 4));

		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 16px;
		}
	}

	@media ${breakpoint('laptop')} {
		width: calc((3 / 12 * 100%) - (2px * 3 / 4));

		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 0;
		}
	}
`

const CardContent = styled.div`
	z-index: 5;
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 20px;
	width: 100%;
	color: ${getColor('white')};
	padding: 0 20px;

	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		li {
			display: flex;
			align-items: center;
			text-transform: uppercase;

			&:not(:last-child)::after {
				content: '•';
				font-size: 0.75em;
				display: inline-block;
				color: ${getColor('orange')};
				margin: 0 1em;
			}

			@media ${breakpoint('tablet')} {
				margin-bottom: 12px;

				h6 {
					font-weight: 700;
				}
			}
		}
	}

	p {
		font-size: 16px;
		margin-bottom: 20px;

		@media ${breakpoint('tablet')} {
			font-size: 18px;
			margin-bottom: 42px;
		}
	}

	h5 {
		font-weight: 700;
		display: block;
		text-transform: uppercase;
		text-decoration: underline;

		@media ${breakpoint('tablet')} {
			text-decoration: none;
		}
	}

	@media ${breakpoint('tablet')} {
		padding: 0 36px 40px;
		border-bottom: 2px solid ${getColor('white')};
		margin-bottom: 60px;
	}
`

const HomeNewsCard = ({ post }) => {
	const { post: postObj } = post

	if (!postObj || postObj.length <= 0) {
		return null
	}

	const { title: postTitle, uri, categories, featuredImage } = postObj

	return (
		<Card>
			<WpLink uri={uri} title={postTitle} isBlog={true}>
				{featuredImage && (
					<img
						src={featuredImage.node.mediaItemUrl}
						alt={postTitle}
						className="bg-cover"
					/>
				)}
				<CardContent>
					<ul>
						{categories &&
							categories.nodes.map(function (category) {
								return (
									<li key={category.slug}>
										<h6>{category.name}</h6>
									</li>
								)
							})}
					</ul>

					<p>{postTitle}</p>

					<h5>Read More</h5>
				</CardContent>
			</WpLink>
		</Card>
	)
}

HomeNewsCard.propTypes = {
	post: PropTypes.object,
}

HomeNewsCard.defaultProps = {
	post: {},
}

export default HomeNewsCard
