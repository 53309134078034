import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { blogURI } from '../../../globals.js'
import { breakpoint, getColor } from '../../shared/theme'

import HomeNewsCard from '../homeNewsCard'
import { NewsLinkCard } from '../newsLinkCard'
import { HomeSectionHeader } from '../homeSectionHeader'
import SectionTransition from '../../elements/activeSectionDefault'

const HomeNews = styled.div`
	position: relative;
	padding-top: 40px;
	background: ${getColor('greyLight')};
	display: grid;

	@media ${breakpoint('tablet')} {
		padding-top: 80px;
		flex-wrap: nowrap;
	}
`

const HomeNewsGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`

const Blank = styled.div`
	width: 100%;
	height: 0;
	opacity: 0;

	@media ${breakpoint('tablet')} {
		width: calc((6 / 12 * 100%) - (2px * 3 / 4));
	}

	@media ${breakpoint('laptop')} {
		width: calc((3 / 12 * 100%) - (2px * 3 / 4));
	}
`

const HomeNewsInner = styled(SectionTransition)``

export const NewsAndMore = ({ isActive, links }) => {
	const relatedLink = {
		title: 'News and more',
		url: `/${blogURI}/`,
		target: null,
	}

	if (!links || links.length <= 0) {
		return null
	}

	let spares = links.length < 4 ? [] : false;
	if (links.length < 4)
	{
		const emptyColumns = 4 - links.length;
		for (let i = 0; i < emptyColumns; i++)
		{
			spares.push(i)
		}
	}

	return (
		<HomeNews>
			<HomeNewsInner isActive={isActive}>
				<HomeSectionHeader link={relatedLink} isActive={isActive} />
				<HomeNewsGrid>
					{links.map((post, index) => {
						if (post.type === 'news') {
							return <HomeNewsCard key={index} post={post} />
						} else {
							return <NewsLinkCard key={index} post={post} />
						}
					})}
					{spares && spares.map((col, index) => {
						return <Blank key={index} />
					})}
				</HomeNewsGrid>
			</HomeNewsInner>
		</HomeNews>
	)
}

NewsAndMore.propTypes = {
	isActive: PropTypes.bool,
	links: PropTypes.array,
}

NewsAndMore.defaultProps = {
	isActive: true,
	links: [],
}
