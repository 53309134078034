import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { breakpoint, getColor, ease } from '../../shared/theme'

const Card = styled.div`
	width: 100%;
	position: relative;
	color: ${getColor('white')};
	background: ${getColor('black')};
	overflow: hidden;

	img {
		opacity: 0.8;
		transition: all 200ms ${ease};
	}

	&:hover {
		img {
			opacity: 0.6;
			transform: scale(1.05);
		}
	}

	&::before {
		content: '';
		width: 100%;
		display: block;
		height: 0;
		padding-top: 60%;

		@media ${breakpoint('tablet')} {
			padding-top: 100%;
		}

		@media ${breakpoint('laptop')} {
			padding-top: 157.5%;
		}
	}

	@media ${breakpoint('tablet')} {
		width: calc((6 / 12 * 100%) - (2px * 3 / 4));

		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 16px;
		}
	}

	@media ${breakpoint('laptop')} {
		width: calc((3 / 12 * 100%) - (2px * 3 / 4));

		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 0;
		}
	}

	> a {
		position: relative;
		display: flex;
		align-items: flex-end;
	}
`

const CardContent = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 5;
	margin-bottom: 20px;
	width: 100%;
	color: ${getColor('white')};
	padding: 0 20px;

	@media ${breakpoint('tablet')} {
		padding: 0 36px 40px;
		border-bottom: 2px solid ${getColor('white')};
		margin-bottom: 60px;
	}

	h5 {
		font-weight: 700;
		display: block;
		text-transform: uppercase;
		text-decoration: underline;
		margin-top: 20px;

		@media ${breakpoint('tablet')} {
			margin-top: 42px;
			text-decoration: none;
		}
	}

	@media ${breakpoint('tablet')} {
		padding: 0 36px 40px;
		border-bottom: 2px solid ${getColor('white')};
		margin-bottom: 60px;
	}
`

export const NewsLinkCard = ({ post }) => {
	const { title, post: postObj, featuredImage } = post

	if (!postObj || postObj.length <= 0) {
		return null
	}

	const { uri } = postObj

	return (
		<Card>
			{featuredImage && (
				<img
					src={featuredImage.mediaItemUrl}
					alt={title}
					className="bg-cover"
				/>
			)}
			<CardContent>
				<Link to={uri} title={title}>
					<h3>{title}</h3>
					<h5>Read More</h5>
				</Link>
			</CardContent>
		</Card>
	)
}

NewsLinkCard.propTypes = {
	post: PropTypes.object,
}

NewsLinkCard.defaultProps = {
	post: {},
}
